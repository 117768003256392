import { useState, useEffect } from 'react';
import listStyle from '../xcl_modules/listStyle';
import dropdownstyles from '../xcl_modules/dropdownstyle';
import menuDots from '../css/icons/icon-menudots.png';
import listSortaz from '../css/icons/icon-listsort-az.png';
import listSortza from '../css/icons/icon-listsort-za.png';
import openBox from '../img/openBox.png';
import getTokenData from '../xcl_modules/tokenData';
import { Link, useNavigate } from "react-router-dom";
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';
import ConfirmDelete from '../components/ConfirmDelete';
import getLinks from '../xcl_modules/links';
import Swal from 'sweetalert2';

const menuDotsAlt = "Open the context menu for this item";
const listSortazAlt = "Column sorted in ascending order, click to change";
const listSortzaAlt = "Column sorted in descending order, click to change";
const openBoxAlt = "Empty box";

const startData = [
    {
        ID: 100,
        brand_logo: "|||",
        name: "|||",
        profilename: "|||",
        created: "|||",
        modified: "|||"
    },
    {
        ID: 200,
        brand_logo: "|||",
        name: "|||",
        profilename: "|||",
        created: "|||",
        modified: "|||"
    },
    {
        ID: 300,
        brand_logo: "|||",
        name: "|||",
        profilename: "|||",
        created: "|||",
        modified: "|||"
    }
]
const startShowkeys = '["brand_logo","published","name","filetype","profilename","created","modified"]';
//const tableHeaders = ['Template Logo', 'Live', 'Link Name', 'Type', 'Brand Template', 'Created On', 'Last Modified'];
//const sortablekeys = ['name','profilename','created','modified'];
//const sortablekeys = ["name","created","modified"];
//const sortablekeyDirs = ['','','az','','','za','za'];
// settings
//const itemsPerPage = 6;


export const List = (props) => {
    const [hookedData, setData] = useState( startData );
    const [showkeys, setShowkeys] = useState( startShowkeys );
    const [cap, setCap] = useState( 1 );
    const [len, setLen] = useState( 0 );
    const [dirs, setDirs] = useState ( [] );
      // empty data
    const tableHeaders = JSON.parse(props.tableHeaders);
    const sortablekeys = JSON.parse(props.sortablekeys);
    //const sortablekeyDirs = JSON.parse(props.sortablekeyDirs);
    
    // get table from api
    
    useEffect(() => {
        const cachedLinks = getLinks();

        const getData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_BE+'/links', {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json', 
                        Authorization: `Bearer ${getTokenData().accessToken}`}
                });
                const result = await response.json();
                if (result.code === 202) {
                    // there are no links :(
                    result.list = [ { ID:0, listEmpty: "message" } ];
                } else {
                    setCap(result.linkcap);
                    setLen(result.list.length);
                    setShowkeys(props.showkeys);
                    setDirs( JSON.parse(props.sortablekeyDirs) );
                    if (localStorage.getItem("editLinkID") !== "") {
                        if (cachedLinks.length === result.list.length) {
                            // nothing changed, but we have an edit
                            Swal.fire({
                                position: "top",
                                iconHtml:  '<div class="positoast"></div>',
                                title: "Your edits have been saved!",
                                showConfirmButton: false,
                                timer: 1500,
                                toast: true,
                                timerProgressBar: true,
                                showClass: {
                                    popup: 'toastCongratsOn'
                                },
                                hideClass: {
                                    popup: 'toastCongratsOff'
                                }
                            });
                        }
                        if (cachedLinks.length < result.list.length) {
                            // we have a new 
                            Swal.fire({
                                position: "top",
                                iconHtml:  '<div class="positoast"></div>',
                                title: "Learning link successfully created!",
                                showConfirmButton: false,
                                timer: 1500,
                                toast: true,
                                timerProgressBar: true,
                                showClass: {
                                    popup: 'toastCongratsOn'
                                },
                                hideClass: {
                                    popup: 'toastCongratsOff'
                                }
                            });
                        }
                        localStorage.setItem("editLinkID", "");
                    }

                    props.setCapAndLen(result.linkcap, result.list.length);
                    localStorage.setItem("xcllinks", JSON.stringify(result.list));
                }
                //setTemplates(result.profiles.length);
                setData(result.list);
            } catch {
                Swal.fire({
                    position: "top",
                    iconHtml:  '<div class="positoast"></div>',
                    title: "System Error :O  Please Login again.",
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true,
                    timerProgressBar: true,
                    showClass: {
                        popup: 'toastCongratsOn'
                    },
                    hideClass: {
                        popup: 'toastCongratsOff'
                    }
                });
            }
        }
        getData();
    }, [props]);

    const SortableIcon = (props) => {
        if (dirs[props.ordinal] === "za") {
            return (
                <img src={listSortza} alt={listSortzaAlt} key={props.icoKey} style={{cursor: "pointer"}} onClick={() => sortAZ(props)} />
            );
        } else {
            return (
                <img src={listSortaz} alt={listSortazAlt} key={props.icoKey} style={{cursor: "pointer"}} onClick={() => sortZA(props)} />
            );
        }
    }
    
    const sortAZ = (props) => {
        const tmpData = hookedData;
        
        switch (props.column) {
            case "Created On":
                tmpData.sort(function(a, b) {
                    let keyA = a.created,
                        keyB = b.created;
                    // Compare the 2 dates
                    if (keyA < keyB) return 1;
                    if (keyA > keyB) return -1;
                    return 0;
                });
                break;
            case "Last Modified":
                tmpData.sort(function(a, b) {
                    let keyA = a.modified,
                        keyB = b.modified;
                    // Compare the 2 dates
                    if (keyA < keyB) return 1;
                    if (keyA > keyB) return -1;
                    return 0;
                });
                break;
            default: 
                tmpData.sort(function(a, b) {
                    let keyA = a.name,
                        keyB = b.name;
                    // Compare the 2 dates
                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                });
                break;
        }


        const tmpDirs = dirs;
        tmpDirs[props.ordinal] = 'az';
        setDirs( [...tmpDirs] );
        
        setData( [...tmpData] );
    }
    
    const sortZA = (props) => {
        const tmpData = hookedData;

        switch (props.column) {
            case "Created On":
                tmpData.sort(function(a, b) {
                    let keyA = a.created,
                        keyB = b.created;
                    // Compare the 2 dates
                    if (keyA > keyB) return 1;
                    if (keyA < keyB) return -1;
                    return 0;
                });
                break;
            case "Last Modified":
                tmpData.sort(function(a, b) {
                    let keyA = a.modified,
                        keyB = b.modified;
                    // Compare the 2 dates
                    if (keyA > keyB) return 1;
                    if (keyA < keyB) return -1;
                    return 0;
                });
                break;
            default: 
                tmpData.sort(function(a, b) {
                    let keyA = a.name,
                        keyB = b.name;
                    // Compare the 2 dates
                    if (keyA > keyB) return -1;
                    if (keyA < keyB) return 1;
                    return 0;
                });
                break;
        }
        
        const tmpDirs = dirs;
        tmpDirs[props.ordinal] = 'za';
        setDirs( [...tmpDirs] );
        
        setData( [...tmpData] );
    }

    const setCapAndLen = () => {
        
        const getData = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_BE+'/links', {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json', 
                        Authorization: `Bearer ${getTokenData().accessToken}`}
                });
                const result = await response.json();
                if (result.code === 202) {
                    // there are no links :(
                    result.list = [ { ID:0, listEmpty: "message" } ];
                    props.setCapAndLen(1, 0);
                } else {
                    setCap(result.linkcap);
                    setLen(result.list.length);
                    props.setCapAndLen(result.linkcap, result.list.length);
                }
                //setTemplates(result.profiles.length);
                setData(result.list);
            } catch {
                Swal.fire({
                    position: "top",
                    iconHtml:  '<div class="positoast"></div>',
                    title: "System Error :O  Please Login again.",
                    showConfirmButton: false,
                    timer: 1500,
                    toast: true,
                    timerProgressBar: true,
                    showClass: {
                        popup: 'toastCongratsOn'
                    },
                    hideClass: {
                        popup: 'toastCongratsOff'
                    }
                });
            }
        }
        getData();
    }

    let currentShowKeys = JSON.parse(showkeys);

    return (
        <>
            <style>{listStyle}{dropdownstyles}</style>
            <table className='listtable'>
                <tbody>
                    <tr>
                        { tableHeaders.map((header, i) => 
                            <th key={currentShowKeys[i]}>{header}
                            { sortablekeys.includes(currentShowKeys[i]) ? (
                                <SortableIcon dir="az" icoKey={'icon_'+i} ordinal={i} column={header} />
                            ) : ( <></> ) }
                            </th>
                        ) }
                        <th></th>
                    </tr>
                    { (hookedData[0].listEmpty) ? (
                        <tr>
                            <td colSpan={currentShowKeys.length} className='stophover'>
                                <div className='emptyTable links'>
                                    <img src={openBox} alt={openBoxAlt}/>
                                    <h3>{props.emptyheading}</h3>
                                        
                                            { (props.profiles===0) ? (
                                                <p>
                                                    <Link to={props.linktoAlt}>
                                                        {props.createprofilelink}
                                                    </Link>
                                                    {props.emptyinstruction}
                                                </p>
                                            ): (<></> )}
                                    <Link to={(props.profiles>0 && cap>=len) ? props.linkto : ''} className={"emptyTable links " + ((props.profiles>0 && cap>=len) ? '' : 'disabled')}>
                                        {props.linklabel}
                                    </Link>
                                </div>
                            </td>
                        </tr>
                    ) : hookedData.map((rowData, i) =>
                        <Row 
                        key={'row_'+i} 
                        rowID={rowData.ID} 
                        rowKey={rowData.ID+'_'+i} index={i} 
                        data={JSON.stringify(rowData)} 
                        linkHref={`${process.env.REACT_APP_LINK_HOST}/?m=${rowData.accountID}&n=${rowData.linkID}`}
                        doSelectRow={() => props.doSelectRow(rowData.ID)} 
                        doSetCapAndLen={ setCapAndLen }
                        showkeys={showkeys}
                        />
                    )}
                </tbody>
            </table>
        </>
    )
}

const Row = (props) => {
    
    const [copyMsg, setcopyMsg] = useState( "Copy Link URL" );
    
    const showkeys = JSON.parse(props.showkeys);
    //console.log(showkeys);
    // map to cols
    const rowKey = props.rowKey;
    const theData = JSON.parse(props.data);

    const [deleteConfirm, setDeleteConfirm] = useState(false);

    const nav = useNavigate();

    const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(4);

    // get the linkID
    const linkID = (theData.linkID) ? theData.linkID : "";
    const logoBgcolor = "#f2f5f5";

    const thisData = Object.keys(theData)
    .filter(key => showkeys.includes(key))
    .reduce((obj, key) => {
        obj[key] = theData[key];
        return obj;
    }, {});
    
    const doDelete = async () => {
        // throw up a popup to verify, then do this
        try {
            const response = await fetch(process.env.REACT_APP_BE+'/link', {
                method: 'DELETE',
                headers: {'Content-Type': 'application/json', 
                    Authorization: `Bearer ${getTokenData().accessToken}`},
                body: JSON.stringify( { linkID: linkID } )
            });
            const result = await response.json();
            
            if (result.status === "success") {
                // it was deleted
                // show green announce
                props.doSetCapAndLen();
            } else {
                // error
                // show red announce
            }
            
        } catch {
            Swal.fire({
                position: "top",
                iconHtml:  '<div class="positoast"></div>',
                title: "System Error :O  Please Login again.",
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                timerProgressBar: true,
                showClass: {
                    popup: 'toastCongratsOn'
                },
                hideClass: {
                    popup: 'toastCongratsOff'
                }
            });
        }
        setIsOpen(false);
        setDeleteConfirm(false);
    }
    
    const doConfirmDelete = () => {
        setDeleteConfirm(true);
    }

    const deleteConfirmClose = () => {
        setDeleteConfirm(false);
        nav("/xcl/links");
    }

    const doEdit = () => {
        localStorage.setItem("editLinkID", props.rowID);
        nav("/linkname");
    }

    const doViewLink = () => {
        console.log("view link " + linkID);
    }

    const doCopyLink = () => {
        navigator.clipboard.writeText(props.linkHref);
        setcopyMsg("Link copied!");
    }
    
    return (
        <tr key={rowKey+'_tr'}>
            { showkeys.map((key, i) =>
                <Cell key={'cell_'+i} cellKey={rowKey+"_"+i} rowID={props.rowID} data={thisData[key]} datakey={key} linkID={linkID} logoBgcolor={logoBgcolor} />
            )}
            <td className='listmenu' key={rowKey+'_td2'}>
                <button key={rowKey+'_btn'} {...buttonProps}>
                    <img src={menuDots} alt={menuDotsAlt} key={rowKey+'_img'} />
                    Menu
                </button>
                <div style={{ position: "absolute" }}>
                    <div className={"listMenu " + (isOpen ? 'visible' : '')} role='menu' style={{ top: "-32px", right: "-77px" }}>
                        <Link {...itemProps[0]} onClick={doCopyLink}>{copyMsg}</Link>
                        <Link {...itemProps[1]} onClick={doViewLink} to={props.linkHref} target="_blank">View Link</Link>
                        <Link {...itemProps[2]} onClick={doEdit} to="/linkname">Edit</Link>
                        <Link {...itemProps[3]} onClick={doConfirmDelete}>Delete</Link>
                    </div>
                </div>
                <ConfirmDelete 
                object="Learning Link"
                objectname={thisData.name}
                onConfirm={doDelete}
                open={deleteConfirm} 
                number={0} 
                onClose={deleteConfirmClose} 
                doSelectRow={() => props.doSelectRow(theData.ID)} 
                />
            </td>
        </tr>
    )
}

const Cell = (props) => {

    let data = props.data;
    const datakey = props.datakey;
    const cellKey = props.cellKey;

    const doLogoClick = () => {
        localStorage.setItem("editLinkID", props.rowID);
    }

    // dates
    if ((datakey.toLowerCase().indexOf("date") > -1 || datakey.toLowerCase().indexOf("created") > -1 || datakey.toLowerCase().indexOf("modified") > -1) && (data !== "|||")) {
        const dateOptions = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
        data = new Date(data).toLocaleDateString('en-US',dateOptions);
    }
    // logos
    if ((datakey.toLowerCase().indexOf("brand_logo") > -1) && (data !== "|||")) {
        const imgSrc = data;
        const linkID = props.linkID;
        const logoBgcolor = props.logoBgcolor;
    
        if (linkID) {
            data = (
                <Link to="/linkname" onClick={doLogoClick} style={{ backgroundColor: logoBgcolor }} className='logolink' title='Edit this Link'>
                    <img src={imgSrc} alt="brand logo" style={{ height:"56px"}} />
                </Link>
            )
        } else {
            data = (
                <div className='logolink' style={{ backgroundColor: logoBgcolor }}>
                    <img src={imgSrc} alt="brand logo" style={{ height:"56px"}} />
                </div>
            )
        }
    }
    // Published status
    if ((datakey.toLowerCase().indexOf("published") > -1) && (data !== "|||")) {
        if (data===1) {
            data = (
                <span className="material-symbols-rounded">
                    visibility
                </span>
            )
        } else {
            data = (
                <span className="material-symbols-rounded">
                    visibility_off
                </span>
            )
        }
    }
    // types
    if ((datakey.toLowerCase().indexOf("filetype") > -1) && (data !== "|||")) {
        const thisData = ( <div style={{fontSize: "xx-small", marginTop: "-6px"}}>{(data) ? data.toLowerCase() : ""}</div> );
        
        switch ((data) ? data.toLowerCase() : "") {
            case "pptx": data = iconBuilder(thisData, "slideshow"); break;
            case "ppt": data = iconBuilder(thisData, "slideshow"); break;
            case "mp4": data = iconBuilder(thisData, "movie"); break;
            case "video": data = iconBuilder(thisData, "movie"); break;
            case "scorm": data = iconBuilder(thisData, "school"); break;
            case "xapi": data = iconBuilder(thisData, "school"); break;
            case "docx": data = iconBuilder(thisData, "docs"); break;
            case "doc": data = iconBuilder(thisData, "docs"); break;
            case "pdf": data = iconBuilder(thisData, "docs"); break;
            case "website": data = iconBuilder((<div style={{fontSize: "xx-small", marginTop: "-6px"}}>weblink</div>), "link"); break;
            case "image": data = iconBuilder(thisData, "image"); break;
            case "jpeg": data = iconBuilder(thisData, "image"); break;
            case "jpg": data = iconBuilder(thisData, "image"); break;
            case "png": data = iconBuilder(thisData, "image"); break;
            default: break;            
        }
    }

    if (data === "|||") {
        // start state lazy loading box
        data = (
            <div style={{ backgroundColor: "#EEEEEE", borderRadius: "6px", height: "1em" }}/>
        )
    }

    return (
        <td key={cellKey}>
            { data ? data : `-` }
        </td>
    )
}

const iconBuilder = (data, icon) => {
    return (
        <div style={{textAlign: "center", width: "34px"}}>
            <span aria-hidden="true" className="material-symbols-rounded">
                {icon}
            </span>
            {data}
        </div>
    )
}